<template>
  <div class="form-layout">
    <div class="form">
      <v-form ref="form" @submit.prevent="validateForm">
        <v-row>
          <v-col cols="12" md="12" lg="12">
            <label class="label-input">{{ $t("department.name") }}</label>
            <v-text-field
                @keydown.enter="focusNext"
                class="mt-2"
                outlined
                dense
                v-model="item.name"
                persistent-hint
                :hint="`${server_errors.name}`"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="12" lg="12">
            <v-btn class="btn-save-change" type="submit" :loading="btnLoading">
              {{ $t("department.saveChange") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      btnLoading: false,
      item: {
        name: ""
      },
      server_errors: {
        name: "",
      }
    }
  },
  methods: {
    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll('input[type="text"]'));
      const index = inputs.indexOf(e.target);
      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    },
    validateForm() {
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        this.saveChange();
      }
    },
    saveChange() {
      this.$axios.post(`company/add/department`, this.item).then((res) => {
        if (res.status === 200) {
          setTimeout(() => {
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
            this.$router.push({name: "department.index"})
          }, 300)
        }
        this.btnLoading = false;
      }).catch((error) => {
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        this.btnLoading = false;
      })
    }
  }
}
</script>

<style scoped lang="scss">
.form-layout {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .form {
    width: 500px;
    height: 400px;
    padding-top: 200px;
  }
}
</style>
